import { addDaysToNow } from "../utils/addDaysToNow"

export const DEFAULT_SHIPPING_INTERVAL = "4 weeks"

export const DEFAULT_CUSTOM_ATTRIBUTES = []

// describes the flat rate shipping fees determined by cart weight
// each tuple value corresponds to a weight in grams and a shipping rate fee
export const DEFAULT_SHIPPING_FEES = [
  [0, "0"],
  [145.15, "1"],
  [1769.01, "2.95"],
  [3583.38, "4.95"],
  [16283.97, "9.95"],
  [16329.3, "19.95"],
]

export const CONTENT_POSITION_DICTIONARY = {
  top: ["flex-start"],
  "top left": ["flex-start", "flex-start"],
  "top center": ["flex-start", "center"],
  "top right": ["flex-start", "flex-end"],
  "center left": ["center", "flex-start"],
  left: ["center", "flex-start"],
  "center center": ["center", "center"],
  center: ["center", "center"],
  right: ["center", "flex-end"],
  "center right": ["center", "flex-end"],
  bottom: ["flex-end"],
  "bottom left": ["flex-end", "flex-start"],
  "bottom center": ["flex-end", "center"],
  "bottom right": ["flex-end", "flex-end"],
}

export const WEIGHT_POUNDS_TO_GRAM = 0.0022046
export const WEIGHT_OUNCES_TO_POUNDS = 16

export const DEFAULT_PURCHASE_TYPE = "subscription"
export const PURCHASE_TYPE_SUBSCRIPTION = "subscription"
export const PURCHASE_TYPE_ONETIME = "onetime"

// cookie name that native Shopify adds to the browser to store URL parameters
export const SHOPIFY_NATIVE_UTM_COOKIE = "_shopify_sa_p"

export const ELEVAR_VISITOR_INFO_COOKIE = "_elevar_visitor_info"

export const ADDITIONAL_PARAMS_TO_COOKIES = {
  irclickid: {
    sameSite: "none",
    expires: addDaysToNow(30),
    secure: true,
  },
}

export const COOKIES_TO_NOTES = [
  ["irclickid", "impact_radius_click_id"],
  [
    "_elevar_visitor_info",
    undefined,
    { named: false, parse: true },
    (cookieName, cookieValue) => {
      if (!cookieValue) {
        return null
      }

      return { [cookieName]: JSON.stringify(cookieValue) }
    },
  ],
  ["_fbp", "_elevar__fbp"],
  ["_ga", "_elevar__ga"],
  ["_ga_T6LSJ1Y0CD", "_elevar__ga_T6LSJ1Y0CD"],
  ["__we_split", undefined, { named: true, doNotParse: true }],
  ["__we_split_act", undefined, { named: true, doNotParse: true }],
  ["__we_split_vid"],
]
